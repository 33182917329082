import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Link } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityValuePill } from '@shapeable/ui';
const cls = classNames('link-value');

// -------- Types -------->

// -------- Props -------->

export type LinkValueProps = Classable & HasChildren & { 
  entity?: Link;
};

export const LinkValueDefaultProps: Omit<LinkValueProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityValuePill)<ContainerProps>`${ContainerStyles}`,
};

export const LinkValue: Shapeable.FC<LinkValueProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <My.Container className={cls.name(className)} entity={entity} variant='smallName' showLabel={false} />
  )
};

LinkValue.defaultProps = LinkValueDefaultProps;
LinkValue.cls = cls;