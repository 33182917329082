import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentNode as TContentNode, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, DottedLine, Icon } from '@shapeable/ui';

import AnimateHeight from 'react-animate-height';
import { SelectIndicator } from './select-indicator';
import { classNames } from '@shapeable/utils';

const cls = classNames('icon-content-reveal');


// -------- Types -------->

export type IconContentRevealProps = Classable & HasChildren & {
  icon: React.ReactNode;
  iconColor?: string;
  iconSize?: number;
  strongLabel?: string;
  description?: TContentNode;
}

export const IconContentRevealDefaultProps: Omit<IconContentRevealProps, 'icon'> = {
  iconColor: 'dark',
  iconSize: 65,
};

// -------- Child Component Props -------->

type ContainerProps = {
  isExpanded: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(7)};
    cursor: pointer;
    
    ${({ isExpanded }: ContainerProps ) => isExpanded && css`
      margin-bottom: ${theme.UNIT(5)};
    `}
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: ${theme.COLOR('strong')};
    font-weight: 500;

   
  `,
});

const IconAndTitleStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(2)};

  `,
});

const IconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
    
  `,
});

const SelectStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled.div`${HeaderStyles}`,
      IconAndTitle: styled.div`${IconAndTitleStyles}`,
        Icon: styled(Icon)`${IconStyles}`,
        Select: styled(SelectIndicator)`${SelectStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
};

export const IconContentReveal: React.FC<IconContentRevealProps> = (props) => {
  const { className, children, iconSize, iconColor, strongLabel, description } = props;
  const icon = props.icon;

  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);

  return (
    <My.Container isExpanded={isExpanded} onClick={(event: any) => { event.stopPropagation(); setIsExpanded((isExpanded) => !isExpanded)}} className={className}>
      <My.Header>
        <My.IconAndTitle>
          <My.Icon size={iconSize} >{icon}</My.Icon>
          {children}
        </My.IconAndTitle>
        <My.Select direction={isExpanded ? 'up' : 'down'} />
      </My.Header>
      {
        isExpanded ? null : <DottedLine gap={5} color='line' />
      }
      <AnimateHeight duration={400} height={isExpanded ? "auto" : 0}>
      {
        isExpanded && 
          <>
            <My.Content entity={description as TContentNode} /> 
            <DottedLine gap={5} color={'line'}/>
          </>
      }
      </AnimateHeight>
    </My.Container>
  )
};

IconContentReveal.defaultProps = IconContentRevealDefaultProps;