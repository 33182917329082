import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, ExplorerEntityGridLayout, OrganisationMarketMapGrid, useOrganisations } from '@shapeable/ui';
import { classNames, entityNormalizedField } from '@shapeable/utils';
import { sortBy } from 'lodash';
const cls = classNames('radar-community-layout');

// -------- Types -------->

export type ExplorerMarketMapLayoutProps = Classable & HasChildren & { 
};

export const ExplorerMarketMapLayoutDefaultProps: Omit<ExplorerMarketMapLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerMarketMapLayout: Shapeable.FC<ExplorerMarketMapLayoutProps> = (props) => {
  const { className } = props;
  const view = 'navigator';
  const gridItems = sortBy(useOrganisations(), entityNormalizedField('name'));

  return (
    <My.Container 
      view={view}
      spacing={0}
      gridComponent={OrganisationMarketMapGrid}
      showSearchBar
      className={cls.name(className)}
      items={gridItems}
    />
  )
};

ExplorerMarketMapLayout.defaultProps = ExplorerMarketMapLayoutDefaultProps;
ExplorerMarketMapLayout.cls = cls;