const gql = String.raw;
  
export const Person = gql`
query PersonBySlugQuery($slug: ID!) {
  personBySlug(slug: $slug) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      hashPath
      openGraph { 
        title description { plain }
        image { url url2x thumbnails { large { url url2x } } }
      }
      hideEmail
      hidePhone
      position
      email
      phone
      twitter
      threads
      linkedin
      type { id name slug }
      colleagues {
        id name slug hashPath path __typename _schema { label pluralLabel }
        position
        organisation { id name }
        openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
      }
      
        entityType { useAi }
        entityViews {
          id name hash label slug count showCount
          type { id name slug }
          slices {
            id name slug label updated
            title 
            titleRich { id text }
            layout { id name slug component }
            openGraph { 
              image { url thumbnails { large { url } bubble { url url2x } } }
            }
            emptyTout { id text }
            ownEmptyTout { id text }
            entityFormFields {
              name
              label
              description
              ownDescription
              type
              entityTypeName
              entityTypeNamePlural
            }
            headerFontType { internalName }
            bodyFontType { internalName }
            disabled
            connectedEntities { name label internalName }
            entityLayout { internalName }
            created updated
            outro { id text }
            intro { id text }
            content { id plain text }
            email
            phone
            twitter
            threads
            linkedin
            backgroundColor { id value }
            color { id value }
            teams { id name slug __typename _schema { label pluralLabel } }
            objectives { id name slug __typename type { id name slug __typename } }
            icon { id slug name component }
            people {
              id name hashPath slug path __typename
              organisation { id name }
              linkedin
              position
              openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            indicators { 
              id name slug title
            }
            indicatorGroups { 
              id slug name 
            }
            economies {
              id name slug path hashPath __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            } 
            locations { 
              id name slug __typename _schema { label pluralLabel } 
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            posts { 
              id name slug hashPath path __typename _schema { label  pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            feedEntries {
              name id slug path hashPath url __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { title image { url thumbnails { full { url } } } }
            }
            pages {
              id name slug path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            organisations { 
              id name slug path hashPath __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            topicExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              topics { __typename outlineNumber id name slug path hashPath 
                openGraph {
                  title description { plain }
                  image { url url2x thumbnails { medium { url url2x } } }
                }
              }
            }
            trendExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              trends { __typename outlineNumber id path hashPath name slug
                openGraph { title description { plain } image { url url2x thumbnails { medium { url url2x } } }
      }
              }
            }
          }
        }
      
    
    entityViews {
      slices {
        technologies {
          id name slug __typename _schema { label pluralLabel }
          openGraph { description { id text } image { url } }
        }
      }
    }
  }
}
`;
