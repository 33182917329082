import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Dictionary, HasChildren, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useLink, useTrends } from '@shapeable/ui';
import { angleFrom12 } from '../../utils';
import { NAVIGATOR_MAP_HEIGHT, NAVIGATOR_MAP_INNER_RADIUS, NAVIGATOR_MAP_OUTER_RADIUS, NAVIGATOR_MAP_WIDTH, PAGE_NAVIGATOR } from '../../data';
import { NavigatorTrendState, useNavigatorMap } from '../../hooks/use-navigator-map';
import { keyBy, mapValues, values, sortBy } from 'lodash';
import { TrendSlice } from '../entities/trend-slice';
import { NavigatorMapCore } from './navigator-map-core';
import { classNames } from '@shapeable/utils';

const cls = classNames('navigator');

export type TrendWithMeta = Trend & {
  naturalHeadAngle: number;
  naturalTailAngle: number;
  orderNumber?: number;
  
};

// -------- Types -------->

export type NavigatorMapProps = Classable & HasChildren & {
  width?: number;
  height?: number;
  innerRadius?: number;
  outerRadius?: number;
  backgroundColor?: string;
  inactiveTrendAngleSize?: number; // now many degrees of a circle should an inactive trend tab render in? 

  trendChangeDuration?: number;
}

export const NavigatorMapDefaultProps: NavigatorMapProps = {
  width: NAVIGATOR_MAP_WIDTH,
  height: NAVIGATOR_MAP_HEIGHT,
  innerRadius: NAVIGATOR_MAP_INNER_RADIUS,
  outerRadius: NAVIGATOR_MAP_OUTER_RADIUS,
  backgroundColor: '#FFFFFF',
  inactiveTrendAngleSize: 18,
  trendChangeDuration: 500,
};

// -------- Child Component Props -------->

type ContainerProps = {
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});


const SliceStyles = breakpoints({
  base: css`
    
  `,
});

const HoleStyles = breakpoints({
  base: css`
    cursor: pointer;
    background-color: #E3EFF0;
  `,
});



const CoreStyles = breakpoints({
  base: css`
    cursor: pointer;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
    TrendSlice: styled(TrendSlice)`${SliceStyles}`,
    Hole: styled.circle`${HoleStyles}`,
      Core: styled(NavigatorMapCore)`${CoreStyles}`,
};

export const NavigatorMap: Shapeable.FC<NavigatorMapProps> = (props) => {
  const { 
    className, width, height, innerRadius, outerRadius, 
    backgroundColor, trendChangeDuration, inactiveTrendAngleSize,
  } = props;

  const trends = useTrends();

  const angleStep = (!!trends.length && (360 / trends.length)) || 180 
  const navigatorMap = useNavigatorMap();

  const { navigate } = useLink();

  const cOriginProps = { cx: width / 2, cy: height / 2 };
  
  const trendsWithMeta: Dictionary<TrendWithMeta> = React.useMemo(() => {
    return keyBy(trends.map((trend, i) => {
      const naturalHeadAngle = (i + 1) * angleStep;
      const naturalTailAngle = (i) * angleStep;
      
      return { ...trend, naturalHeadAngle, naturalTailAngle, orderNumber: i };
    }), 'slug');
  }, [trends]);
  

  const trendsStartState: Dictionary<NavigatorTrendState> = mapValues(trendsWithMeta, trend => {
    const tail = navigatorMap.state.trendState?.[trend.slug]?.tail;
    const head = navigatorMap.state.trendState?.[trend.slug]?.head;

    return {
      slug: trend.slug,
      tail: tail === undefined ? trend.naturalTailAngle : tail,
      head: head === undefined ? trend.naturalHeadAngle : head,
    };
  });

  // this would be a function of the URL (not the CURRENT state!)

  let trendsState = mapValues(trendsWithMeta, (trend) => ({
    isActive: false,
    slug: trend.slug,
    head: trend.naturalHeadAngle,
    tail: trend.naturalTailAngle,
  }));
  
  if (navigatorMap.activeTrend) {
    const activeTrendWithMeta = trendsWithMeta?.[navigatorMap.activeTrend.slug];
    const activeTrendHeadAngle = activeTrendWithMeta?.naturalHeadAngle || 0;
    const trends = sortBy(values(trendsWithMeta), 'orderNumber');

    // calculate how much of the circle is used for the actiuve trend
    const delta = 360 - (inactiveTrendAngleSize * (trends.length - 1));
    const activeTrendTailAngle = (activeTrendHeadAngle - delta);

    
    const activeTrendIndex = trends.findIndex(trend => trend.slug === navigatorMap.activeTrend.slug);
    const newTrends = [];

    // first, place the trends behind this one in the set, behind the tail of the active trend 
    for (let i = activeTrendIndex; i >= 0; i--) {
      const distance = activeTrendIndex - i;
      const trend = trends[i];

      const head = (activeTrendTailAngle - ((distance - 1) * inactiveTrendAngleSize));
      const tail = (head - inactiveTrendAngleSize);

      newTrends.push({
        slug: trend.slug,
        head,
        tail,
        isActive: false,

        // extra debug info
        distance,
        isBefore: true,
        naturalHeadAngle: trend.naturalHeadAngle,
        naturalTailAngle: trend.naturalTailAngle,
      });
    }
    
    // now insert the active trend
    newTrends.push({
      slug: navigatorMap.activeTrend.slug,
      head: activeTrendHeadAngle,
      tail: activeTrendTailAngle,
      
      // extra debug info
      naturalHeadAngle: activeTrendWithMeta?.naturalHeadAngle,
      naturalTailAngle: activeTrendWithMeta?.naturalTailAngle,
      isActive: true,
    });

    // now place the trends in front of this one in the set in front of the head of the active trend
    for (let i = activeTrendIndex + 1; i < trends.length; i++) {
      const trend = trends[i];
      const distance = i - activeTrendIndex;
      const head = activeTrendHeadAngle + ((distance) * inactiveTrendAngleSize);
      const tail = head - inactiveTrendAngleSize;

      newTrends.push({
        slug: trend.slug,
        head,
        tail,
        isActive: false,

        // extra debug info
        distance,
        isBefore: false,
        naturalHeadAngle: trend.naturalHeadAngle,
        naturalTailAngle: trend.naturalTailAngle,
      });
    }

    trendsState = keyBy(newTrends, 'slug');
  };

  

  React.useEffect(() => {
    setTimeout(() => {
      navigatorMap.setTrends(trendsState);
    }, trendChangeDuration);

  }, [])

  return (
    <My.Container className={cls.name(className)} viewBox={`0 0 ${width} ${height}`}>
      {
        values(trendsWithMeta).map((trend, i) => {

          const trendStartState = trendsStartState[trend.slug];
          const trendState = trendsState[trend.slug];
          const headAngle = trendState.head;
          const tailAngle = trendState.tail;
          const startHeadAngle = trendStartState.head; 
          const startTailAngle = trendStartState.tail;

          // console.log("RENDERING [slug, head, tail]", trend.slug, headAngle, tailAngle);
          return (
            <My.TrendSlice 
              headSize={inactiveTrendAngleSize}
              duration={trendChangeDuration}
              onClick={trendState.isActive ? null : (() => { navigate(trend.path); } )}
              key={trend.slug}
              color={trend.color?.value || '#000'}
              entity={trend} 
              isActive={trendState.isActive}
              outerRadius={outerRadius} 
              innerRadius={innerRadius} 
              headAngle={angleFrom12(headAngle)} 
              tailAngle={angleFrom12(tailAngle)} 
              startHeadAngle={angleFrom12(startHeadAngle)}
              startTailAngle={angleFrom12(startTailAngle)}
            />
          );

        })
      }
      <My.Core cx={cOriginProps.cx} cy={cOriginProps.cy} onClick={() => navigate(PAGE_NAVIGATOR.path)} />
    </My.Container>
  )
};

NavigatorMap.cls = cls;
NavigatorMap.defaultProps = NavigatorMapDefaultProps;[

]