import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, cPointAtOrigin, pointAtOrigin } from '@shapeable/utils';
import { OutlineNode } from '../elements/outline-node';
import { animated, useSpring } from '@react-spring/web';
import { useNavigatorMap } from '../../hooks/use-navigator-map';
import { normalizeAngle } from '../../utils';
import { NodeMarker, NodeMarkerDefaultProps } from '../elements/node-marker';
import { useLink, SvgDonutSlice, SvgDonutSliceProps, SvgDonutSliceDefaultProps, SvgTextArc } from '@shapeable/ui';


const cls = classNames('trend-slice');

// -------- Types -------->

export type TrendSliceProps = Omit<SvgDonutSliceProps, 'id'> & { 
  entity?: Trend;
  isActive?: boolean;
  topicPadding?: number;
  topicLineLength?: number;
};

export const TrendSliceDefaultProps: Omit<TrendSliceProps, 'entity' | 'headAngle' | 'tailAngle' | 'headSize' | 'duration'> = {
  ...SvgDonutSliceDefaultProps,
  topicPadding: 42,
  topicLineLength: 30,
};

// -------- Child Component Props -------->

type ContainerProps = {
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TopicsStyles = breakpoints({
  base: css`
  `,
});


const TopicStyles = breakpoints({
  base: css`
  `,
});

const TopicMarkerStyles = breakpoints({
  base: css`
    cursor: pointer;
  `,
});

const LabelStyles = breakpoints({
  base: css`
  `,
});





// -------- Components -------->

const My = {
  Container: styled(SvgDonutSlice)<ContainerProps>`${ContainerStyles}`,
    Topics: styled.g`${TopicsStyles}`,
      Topic: styled(animated(OutlineNode))`${TopicStyles}`,
      TopicMarker: styled(animated(NodeMarker))`${TopicMarkerStyles}`,

    Label: styled(animated(SvgTextArc))`${LabelStyles}`,
};

export const TrendSlice: Shapeable.FC<TrendSliceProps> = (props) => {
  const { 
    className, children, entity, isActive, topicPadding, topicLineLength,
    ...donutSliceProps 
  } = props;

  const { headAngle, tailAngle, outerRadius, headSize, innerRadius, startHeadAngle, startTailAngle } = donutSliceProps;

  const segmentRadius = outerRadius - innerRadius;

  
  const originX = outerRadius;
  const originY = outerRadius;
  const pointAt = pointAtOrigin(originX, originY);
  const cPointAt = cPointAtOrigin(originX, originY);

  const headGap = headSize + 15;
  
  const sliceAngle = headAngle - (tailAngle + headGap);
  const sliceMarkerAngle = headAngle - tailAngle - (headGap / 3);

  const topicAngleSize = isActive ? 
    (sliceAngle / (entity.topics?.length || 1)) : 
    (sliceMarkerAngle / (entity.topics?.length || 1));

  const topics = [...(entity.topics || [])].reverse();

  const { activeTopic, activeTrend } = useNavigatorMap();

  // const [labelCx, labelCy] = cPointAt(headAngle, sliceMarkerAngle);

  // now determine where to render the topic label.
  // we want to render it horizontally at either the top or the bottom of the slice.
  // if the mid-poiny between the head and the tail is in the lower half of the circle, render at
  // the top. otherwise, render at the bottom.

  // calculate the shortest distance between the tail angle and the head angle on a circle
  const difference = Math.abs(headAngle - tailAngle);
  const distanceBetween = Math.min(difference, 360 - difference);
  const midAngle = normalizeAngle(headAngle + (distanceBetween / 2));

  // use that to determine of the label is at the top or bottom
  const showLabelAtTop = midAngle < 180;

  const activeLabelRadius = showLabelAtTop ? (outerRadius - 30) : (outerRadius - 20);
  const labelRadius = outerRadius - 35;

  const isTabLabel = activeTrend && activeTrend?.slug !== entity.slug;
  // console.log(entity.slug, isInactive, activeTrend?.slug)

  const tabLabelInfo: any = {
    labelRadius: 70,
    ascending: true,
    suffix: null,
    labelStart: pointAt(innerRadius + segmentRadius - 30, headAngle + 1),
    labelEnd: pointAt(innerRadius + 30, headAngle + 1),
    text: `${(entity.label || entity.name).toUpperCase()}`,
    fontSize: 14,
  };

  const labelInfo = isActive ? {
    labelStart: pointAt(activeLabelRadius, showLabelAtTop ? 210 : 150),
    labelEnd: pointAt(activeLabelRadius, showLabelAtTop ? 330 : 30),
    labelRadius: activeLabelRadius,
    ascending: showLabelAtTop,
    suffix: <tspan style={{ fontWeight: 400 }}>&nbsp;SYSTEM</tspan>,
    fontSize: 18,
    text: `${(entity.label || entity.name).toUpperCase()}`,
  } : (
    isTabLabel ? tabLabelInfo :
    {
      // render the label along the radius of the circle
      labelRadius,
      ascending: true,
      labelStart: pointAt(labelRadius, tailAngle),
      labelEnd: pointAt(labelRadius, headAngle + 30),
      text: `${(entity.label || entity.name).toUpperCase()}`,
      fontSize: 18,
    }
  );


  const [ labelX1, labelY1 ] = labelInfo.labelStart;
  const [ labelX2, labelY2 ] = labelInfo.labelEnd;

  const [ tabLabelX1, tabLabelY1 ] = tabLabelInfo.labelStart;
  const [ tabLabelX2, tabLabelY2 ] = tabLabelInfo.labelEnd;

  // console.log(entity.slug, 'head:', headAngle, 'tail:', tailAngle);

  // if (isActive) {
  //   console.log(entity.slug, 'distance:', distanceBetween, 'head:', nHeadAngle, 'tail:', nTailAngle, 'mid:', midAngle);
  // }

  const { navigate } = useLink();
  
  const isAnimated = headAngle !== startHeadAngle || startTailAngle !== tailAngle;

  const animatedLabelValues = useSpring({
    from: {
      opacity: 0.0
    },
    to: {
      opacity: 1.0,
    },
    config: { duration: 500 }
  });

  const labelValues = isAnimated ? animatedLabelValues : { opacity: 1 };

  const entityOnClick = () => { navigate(entity.path) };

  
  const marker = <tspan style={{ fontSize: 24, fontFamily: `'IBM Plex Sans', sans-serif`, fontWeight: 500 }}>&nbsp;›</tspan>;

  return (
    <My.Container {...donutSliceProps} highlightOnHover={!isActive} id={entity.slug} className={cls.name(className)}>
      <My.Label 
        onClick={entityOnClick}
        style={{ opacity: labelValues.opacity }}
        ascending={labelInfo.ascending} 
        suffix={labelInfo.suffix}
        r={labelInfo.labelRadius} 
        marker={marker}
        x1={labelX1} x2={labelX2} y1={labelY1} y2={labelY2} 
        id={`${entity.slug}`} fontSize={labelInfo.fontSize}>
        {labelInfo.text}
      </My.Label>
      {
        isActive &&
        <My.Label 
          onClick={entityOnClick}
          style={{ opacity: labelValues.opacity }}
          ascending={tabLabelInfo.ascending} 
          suffix={tabLabelInfo.suffix}
          marker={marker}
          r={tabLabelInfo.labelRadius} 
          x1={tabLabelX1} x2={tabLabelX2} y1={tabLabelY1} y2={tabLabelY2} 
          id={`active-${entity.slug}`} fontSize={tabLabelInfo.fontSize}>
          {tabLabelInfo.text}
        </My.Label>
      }
      {
        !!topics.length && (
        <My.Topics>
        {
          isActive ? (
            topics.map((topic, i) => {
              const isActive = activeTopic?.slug === topic.slug;

              const animatedValues = useSpring({
                from: {
                  opacity: 0,
                  dotSize: 4,
                  fontSize: 0,
                },
                to: {
                  opacity: 1.0,
                  dotSize: 42,
                  fontSize: 15,
                },
                config: { duration: 300 },
                delay: (topics.length - i) * 20,
              });

              const values = isAnimated ? animatedValues : {
                opacity: 1,
                dotSize: 42,
                fontSize: 15,
              }; 

              // we don't need to use angleFrom12, since the headAngle already takes this into account
              const angle = headAngle - (topicAngleSize * i);
              const labelAngle = angle;
              const { cx, cy } = cPointAt(innerRadius + topicPadding, angle);

              // console.log(topic.slug, angle, labelAngle);

              return (
                <My.Topic
                  id={topic.slug}
                  style={{ opacity: values.opacity }}
                  key={topic.slug} 
                  onClick={() => { navigate(topic.path) }}
                  size={values.dotSize}
                  fontSize={values.fontSize}
                  isActive={isActive}
                  lineLength={topicLineLength}
                  activeChromeColor={entity.color.value}
                  activeColor={'#FFFFFF'}
                  activeLabelColor={'#000000'}
                  color={entity.color.value} 
                  label={topic.label || topic.name} 
                  labelAngle={labelAngle} 
                  cx={cx}
                  cy={cy}>{topic.outlineNumber}</My.Topic>
              );
            })
          ) : !activeTrend &&
          (
            topics.map((topic, i) => {

              const values = useSpring({
                from: {
                  opacity: 0,
                  dotRadius: 0,
                  lineDotRadius: 0,
                  lineLength: NodeMarkerDefaultProps.lineLength / 2,
                },
                to: {
                  opacity: 1.0,
                  dotRadius: NodeMarkerDefaultProps.dotRadius,
                  lineDotRadius: NodeMarkerDefaultProps.lineDotRadius,
                  lineLength: NodeMarkerDefaultProps.lineLength,
                },
                config: { duration: 300 },
                delay: (topics.length - i) * 20,
              });

              // we don't need to use angleFrom12, since the headAngle already takes this into account
              const angle = headAngle - (topicAngleSize * i) + 21;
              const { cx, cy } = cPointAt(innerRadius + 52, angle);

              return (
                <My.TopicMarker 
                  id={topic.slug} 
                  onClick={entityOnClick}
                  style={{ opacity: values.opacity }}
                  lineLength={values.lineLength}
                  key={`marker-${topic.slug}`} 
                  angle={angle} 
                  cx={cx} 
                  cy={cy} 
                />
              )
            })
          )
        }
        </My.Topics> 
        )
        
      }
    </My.Container>
  )
};

TrendSlice.defaultProps = TrendSliceDefaultProps;
TrendSlice.cls = cls;