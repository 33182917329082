import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Instrument } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCard, EntityCardProps, EntityValue, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('instrument-card');

// -------- Types -------->

export type InstrumentCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Instrument;
};

export const InstrumentCardDefaultProps: Omit<InstrumentCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const InstrumentStyles = breakpoints({
  base: css`

  `,
});



// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
    Instrument: styled(EntityValue)`${InstrumentStyles}`,
};

export const InstrumentCard: Shapeable.FC<InstrumentCardProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);

  const { types = [] } = entity || {};

  const instrumentType = types[0]?.name;
  
  return (
   <My.Container 
    {...rest} 
    className={cls.name(className)}
    showTypeHeader={false}
    head={
      <My.Instrument entity={entity} variant='default' badge={instrumentType} />
    }
    >
    {children}
   </My.Container>
  )
};

InstrumentCard.defaultProps = InstrumentCardDefaultProps;
InstrumentCard.cls = cls;