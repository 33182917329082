import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Organisation } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteFooterLayout, ComponentPropsProvider, EntityFilters, useInfiniteScrollArray, useOrganisations, useSearch, useView, usePeople, ExplorerEntityGridLayout } from '@shapeable/ui';
import { classNames, linkedMatchesFilter, entityNormalizedField } from '@shapeable/utils';
import { compact, sortBy } from 'lodash';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { Person } from '@shapeable/adsw-types';
const cls = classNames('explorer-community-layout');

// -------- Types -------->

export type ExplorerCommunityLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerCommunityLayoutDefaultProps: Omit<ExplorerCommunityLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerCommunityLayout: Shapeable.FC<ExplorerCommunityLayoutProps> = (props) => {
  const { className, children } = props;

  const view = 'navigator';
  const { resultsFor, filters } = useView(view);
  const { types = [], countries = [], trends = [], topics = [] } = filters;

  const allOrganisations = sortBy(useOrganisations(), entityNormalizedField('name'));
  const allPeople = sortBy(usePeople(), entityNormalizedField('name'));

  const { selects, typeToggles } = useCommunityFilters();


  const organisations = allOrganisations.filter((organisation: Organisation) => (
    linkedMatchesFilter(organisation.countries, countries) &&
    linkedMatchesFilter(compact([organisation?.type]), types)
  ));

  const people = allPeople.filter((person: Person) => (
    linkedMatchesFilter([person.organisation.country], countries)
  ));

  const stakeholders = [
    ...(resultsFor({ type: 'Organisation', data: organisations, by: 'name' })),
    ...(resultsFor({ type: 'Person', data: people, by: 'name' }))
  ];

  return (
    <ComponentPropsProvider value={{ 
      EntityBubbleImage: {
        showBorder: true,
      },
     }}>
      <My.Container 
        className={cls.name(className)}
        filters={
          <My.Filters
            view={view}
            selects={selects}
            typeToggles={typeToggles}
          /> 
        }
        items={stakeholders}
      />
    </ComponentPropsProvider>
  )
};

ExplorerCommunityLayout.defaultProps = ExplorerCommunityLayoutDefaultProps;
ExplorerCommunityLayout.cls = cls;