import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/adsw-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { TrendView } from '../../types';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
const cls = classNames('trend-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendTabsLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendTabsLayoutDefaultProps: Omit<TrendTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const TabsStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TrendTabsLayout: Shapeable.FC<TrendTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color, citations = [], feedEntries = [] } = entity;

  const view = `trend-${entity.slug}`;

  const trendReports = [...citations, ...feedEntries];
  const hasReports = !!trendReports.length;

  const tabs: ContentTabSpec<TrendView>[] = compact([
    {
      id: 'navigator-ai',
      label: 'ShapeAI',
      icon: <AiLowerCaseIconGlyph size={32} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
      hasReports && {
      id: 'reports',
      label: 'Resources',
      count: trendReports.length,
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(view, 'overview');
  
  return (
    <>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value, hover: { text: color?.value } }} />
      }
    </>
  )
};

TrendTabsLayout.defaultProps = TrendTabsLayoutDefaultProps;
TrendTabsLayout.cls = cls;