import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, useView, ExplorerEntityGridLayout, useEconomies } from '@shapeable/ui';
import { classNames, linkedMatchesFilter, entityNormalizedField } from '@shapeable/utils';
import { useEconomyFilters } from '../../hooks/use-economy-filters';
import { Economy } from '@shapeable/adsw-types';
import { sortBy } from 'lodash';
const cls = classNames('radar-community-layout');

// -------- Types -------->

export type ExplorerCountriesLayoutProps = Classable & HasChildren & { 
};

export const ExplorerCountriesLayoutDefaultProps: Omit<ExplorerCountriesLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerCountriesLayout: Shapeable.FC<ExplorerCountriesLayoutProps> = (props) => {
  const { className } = props;
  const view = 'navigator';
  const { resultsFor, filters } = useView(view);
  const { globalRegions = [] } = filters;
  const allEconomies = sortBy(useEconomies(), entityNormalizedField('name'));
  const { selects } = useEconomyFilters();

  const economies = allEconomies.filter((economy: Economy) => (
    linkedMatchesFilter(economy.globalRegions, globalRegions)
  ));

  const gridItems = economies;

  return (
    <My.Container 
      view={view}
      showSearchBar
      className={cls.name(className)}
      items={gridItems}
      filters={
        <My.Filters
          view={view}
          selects={selects}
        />
      }
    />
  )
};

ExplorerCountriesLayout.defaultProps = ExplorerCountriesLayoutDefaultProps;
ExplorerCountriesLayout.cls = cls;