import { ContentNode, EntityTypeName, ImageEntity, Organisation, Page, Trend } from "@shapeable/types";
import { COLOR_1_TO_5_1, COLOR_1_TO_5_2, COLOR_1_TO_5_3, COLOR_1_TO_5_4, COLOR_1_TO_5_5 } from "./theme";

export const NAVIGATOR_MAP_WIDTH = 600;
export const NAVIGATOR_MAP_HEIGHT = 600;
export const NAVIGATOR_MAP_OUTER_RADIUS = NAVIGATOR_MAP_WIDTH / 2; 
export const NAVIGATOR_MAP_INNER_RADIUS = NAVIGATOR_MAP_OUTER_RADIUS / 3; 
export const NAVIGATOR_MAP_ORIGIN_X = NAVIGATOR_MAP_WIDTH / 2; 
export const NAVIGATOR_MAP_ORIGIN_Y = NAVIGATOR_MAP_WIDTH / 2; 



export const GPT_DEFAULT_PROMPTS = [
  "What is the purpose of ADSW as an organisation?",
];

export const PAGE_NAVIGATOR: Page = {
  id: 'page-navigator',
  name: "Net Zero Navigator",
  slug: 'navigator',
  path: '/navigator',
  __typename: 'Page',
};

export const PAGE_GPT: Page = {
  id: 'page-gpt',
  name: "Navigator GPT",
  slug: 'gpt',
  path: '/navigator/gpt',
  __typename: 'Page',
};

export const NET_ZERO_IMAGE: ImageEntity = {
  id: 'net-zero-image',
  image: {
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1700631216/adsw/image-asset/net-zero-image_image__NetZeroNavigatorImage_1_idhkhn.png'
  }
};

export const organisationLogo: Organisation = {
  name: 'Mazdar',
  id: 'hosted-by-logo',
    logo: {
      url: 'https://res.cloudinary.com/shapeable/image/upload/v1700699414/adsw/image-asset/masdar-logo_image__masdar-logo_kbmnzf.png'
    }
  }

export const description: ContentNode = {
  text: "The Navigator aligns with Masdar's global vision for achieving net-zero emissions. Its priorities are to support the transition to sustainable energy, enable technologies for positive climate action, and find innovative solutions to drive sustainable economies while ensuring inclusivity, innovation, and resilience in the face of climate emergencies.",
}

export const horizonIntro: ContentNode = {
  text: "Horizons represent stages in the ongoing transformation of the energy sector. This transformation is measured over time includes technological advancement, policy change, and a societal shift toward a desirable future in which abundant energy is sourced sustainably."
}

export const InstrumentText = "The following Instruments for policy, funding and partnerships address these barriers:"

export type IndicatorValueBarPossibleValues = 1 | 2 | 3 | 4 | 5;
export const IndicatorValueBarColors: Record<IndicatorValueBarPossibleValues, string> = {
  1: COLOR_1_TO_5_1,
  2: COLOR_1_TO_5_2,
  3: COLOR_1_TO_5_3,
  4: COLOR_1_TO_5_4,
  5: COLOR_1_TO_5_5,
};

export const segmentChartDescription: ContentNode = {
  text: "The scores above represent the current state of play within each system of the Navigator and the level of opportunity it represents."
}