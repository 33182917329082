import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { FeedEntriesProvider as Provider, platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';

const FEED_ENTRIES_QUERY = graphql`
  query FeedEntriesQuery {
    platform {
      allFeedEntries {
        name id slug path url __typename _schema { label pluralLabel }
        published author
        openGraph { description { text(truncate: { words: 20 }) } image { url } }
        types { id name slug __typename _schema { label pluralLabel } }
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        organisations { id name slug __typename _schema { label pluralLabel } }
        topics { id name slug path __typename _schema { label pluralLabel } }
        trends { id name slug path __typename _schema { label pluralLabel } }
      }
    }
  }`;

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries' ), 'name') }>{children}</Provider>;
}