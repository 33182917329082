import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SliceLayoutBoundary, SiteHeaderLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ComponentPropsProvider, ExplorerEntityDetailsLayoutProps, BackButtonEntityProvider, ExplorerProvider, ExplorerProviders, SiteHeaderProvider, } from '@shapeable/ui';
import { TopicTabsLayout } from './topic-tabs-layout';
import { TopicViewLayout } from './topic-view-layout';
import { Topic } from '@shapeable/adsw-types';
import { NavigatorMap } from '../elements/navigator-map';
const cls = classNames('topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Topic;
};

export const TopicExplorerLayoutDefaultProps: Omit<TopicExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    .shp--entity-banner {
      h2.shp--entity-value__name {
        font-size: ${theme.FONT_SIZE(35)};
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const MapStyles = breakpoints({
  base: css`
    margin: 0 ${theme.UNIT(6)};
  `,
});
// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Map: styled(NavigatorMap)`${MapStyles}`,

};

export const TopicExplorerLayout: Shapeable.FC<TopicExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  // const { isShown } = useRadarMap();

  return (
    <ExplorerProviders>
    <ComponentPropsProvider value={{
      EntityBanner: {
        maxHeight: 200,
        variant: 'overlay',
        showOverlay: true,
        customAspectRatio: { base: 16 / 9, desktop: 21 / 9 },
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}
      },
    }}>
    <My.Container>
      <SiteHeaderProvider value={{ variant: 'default' }}>
        <My.Header />
      </SiteHeaderProvider>
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        stickyTopOffset={63}
        asideMaxWidth={700}
        className={cls.name(className)}
        // asideIsRevealed={isShown}
        isAsideSticky={true}

        tabs={
          <TopicTabsLayout entity={entity} />
        }
        content={
          <TopicViewLayout entity={entity} />
        }
        // dataVizMain={
        //   <RadarChartMain />
        // }

        dataViz={
          <My.Map />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </ExplorerProviders>
  )
};

TopicExplorerLayout.defaultProps = TopicExplorerLayoutDefaultProps;
TopicExplorerLayout.cls = cls;