import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { OnboardingLayout, SiteHeaderLogo, useIsDarkColor } from '@shapeable/ui';
const cls = classNames('page-layout-setup');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const LayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(OnboardingLayout)`${LayoutStyles}`,
};

export const PageLayoutSetup: PageLayoutComponent = 
(props) => {
  const { className, children, entity } = props;


  return (
    <My.Container className={cls.name(className)}>
      <My.Layout />
      {children}
    </My.Container>
  )
};