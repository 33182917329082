const gql = String.raw;
  
export const Topic = gql`
query TopicBySlugQuery($slug: ID!) {
  topicBySlug(slug: $slug) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      gptName
      outlineNumber
      color { __typename id slug name value }
      trend { id slug name __typename }
      intro { id text }
      description { id text }
      openGraph { 
        title description { plain }
        image { url thumbnails { large { url } } }
      }
      organisations { 
        id name slug url
        type { id name slug }
        technologies { id name slug }
        countries { id slug name path }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      embeds {
        citations {
          slug url name authors { id name slug } 
          authorShowsEtAl edition publication accessDate 
          startPage volume footnoteNumber year
        }
        imageAssets {
          id
          slug
          image {
            id url url2x width height 
          }
        }
      }
      
      video {
        id name url
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      color { id name slug value darkValue veryDarkValue }
      banner {
        id
        image {
          id
          url
          thumbnails {
            mainBanner { id url }
          }
        }
      }
      fileAssets {
        id name slug file { id extension formattedSize url typeName } __typename
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      citations {
        __typename _schema { label pluralLabel }
        id path slug url name path
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
      trend {
        id name path slug __typename
        outlineNumber
        color { id name slug value }
      }
      feedEntries {
        __typename _schema { label pluralLabel }
        id name slug url path
        types { id name slug }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      posts {
        __typename _schema { label pluralLabel }
        id name slug path __typename published
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        people { id name slug path }
      }
      subTopics {
        id name path outlineNumber slug __typename 
        color { id name value } 
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      moderators {
        __typename id name slug position
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        organisation { id name slug }
      }
    
    
    policyTrends { id text }
    fundingTrends { id text }
    partnershipTrends { id text }
    libraryIntro { id text }
    trendIntro { id text }
    stakeholdersIntro { id text }
    instrumentsIntro { id text }
    technologiesIntro { id text }

    technologies {
      id
      name
      slug
      __typename
      _schema {
        label
        pluralLabel
      }
      openGraph {
        image {
          id
          url
        }
      }
      description {
        id
        text
      }
      url
      feedEntries {
        __typename
        _schema {
          label
          pluralLabel
        }
        id
        name
        slug
        url
        path
        websiteName
      }
      organisations {
        id
        name
        slug
        url
        type {
          id
          name
          slug
        }
        technologies {
          id
          name
          slug
        }
        openGraph {
          image {
            thumbnails {
              bubble {
                url
                url2x
              }
              card {
                url
                url2x
              }
            }
          }
        }
        topics {
          id
          name
          slug
          path
        }
      }
      instruments {
        id
        name
        slug
        path
        __typename
        _schema {
          label
          pluralLabel
        }
      }
    }
    instruments {
      id
      name
      slug
      __typename
      _schema {
        label
        pluralLabel
      }
      openGraph {
        image {
          id
          url
        }
      }
      description {
        text
      }
      types {
        id
        name
        slug
        path
      }
    }
  }
}

`;
