import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PeopleProvider as Provider, platformProviderValue } from '@shapeable/ui';

const PEOPLE_QUERY = graphql`
  query PlatformPeopleQuery {
    platform {
      allPeople {
        id name slug hashPath path __typename _schema { label pluralLabel }
        organisation { 
          id name 
          location {
            __typename id slug name
            latitude longitude
          }
        }
        linkedin
        position
        openGraph { image { thumbnails { bubbleLarge { url url2x } bubble { url url2x } card { url url2x } } } }
      }
    }
  }`;

export const PeopleProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(PEOPLE_QUERY), 'allPeople' ) }>{children}</Provider>;
}
