import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Icon, DottedLine } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { ChevronDownIconGlyph, ChevronUpIconGlyph } from '@shapeable/icons';
const cls = classNames('select-indicator');

// -------- Types -------->

export type SelectIndicatorProps = Classable & HasChildren & {
  direction?: 'up' | 'down';
  color?: string;
}

export const SelectIndicatorDefaultProps: SelectIndicatorProps = {
  direction: 'down',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: inline-flex;
    height: 52px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  `,
});

const IconStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Icon: styled(Icon).attrs(cls.attr('icon'))`${IconStyles}`,
};

export const SelectIndicator: Shapeable.FC<SelectIndicatorProps> = (props) => {
  const { className, direction, color } = props;
  return (
    <My.Container className={cls.name(className)}>
      <DottedLine width={2} gap={5} colors={{ border: color }}/>
      <My.Icon size={16} color={color} >{direction === 'down' ? <ChevronDownIconGlyph /> : <ChevronUpIconGlyph />}</My.Icon>
    </My.Container>
  )
};

SelectIndicator.cls = cls;
SelectIndicator.defaultProps = SelectIndicatorDefaultProps;