import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityCard, EntityCardDefaultProps, EntityCardProps, EntityValue } from '@shapeable/ui';

const cls = classNames('trend-card');

// -------- Types -------->

// -------- Props -------->

export type EntityValueCardProps = EntityCardProps & { 
};

export const EntityValueCardDefaultProps: Omit<EntityValueCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showColorStrip: true,
  descriptionMaxLines: 4,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

    .shp--entity-card-v2__titles {
      display: none;
    }
  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const EntityValueCard: Shapeable.FC<EntityValueCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;

  const { type } = entity;

  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      head={<EntityValue entity={entity} variant='default' badge={type?.name} />}
      entity={entity} 
      showDescription
    />
  )
};

EntityValueCard.defaultProps = EntityValueCardDefaultProps;
EntityValueCard.cls = cls;