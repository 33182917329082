import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { useThemeEval } from '@shapeable/ui';
const cls = classNames('navigator-map-core');


// -------- Types -------->

export type NavigatorMapCoreProps = Classable & HasChildren & {
  cx?: number;
  cy?: number; 
  onClick?: () => void;
}

export const NavigatorMapCoreDefaultProps: NavigatorMapCoreProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  targetColor: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ targetColor }: ContainerProps ) => css`
      
      ${cls.dot('target')} {
        transition: fill 0.2s;
      }

      &:hover {
        ${cls.dot('target')} {
          fill: #444; /* This fades the mask down a little which has the effect of lighteting the centre of the circle with the background still behind */
        }
      }
    `}
  `,
});

// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
};

export const NavigatorMapCore: Shapeable.FC<NavigatorMapCoreProps> = (props) => {
  const { className, cx, cy, onClick } = props;
  const size = 176;
  const origin = size / 2;

  const $ = useThemeEval();

  const targetColor = '#E3EFF0';
  const x = cx - origin;
  const y = cy - origin;

  return (
    <My.Container targetColor={targetColor} onClick={onClick} y={y} x={x} className={cls.name(className)} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">

      {/* <defs>
        <mask id="navigator-map-mask">
          <circle cx={origin} cy={origin} r="86" fill="white"/>
          <circle className={cls.sub('target')} cx={origin} cy={origin} r="60" fill="black" />
        </mask>
      </defs>  */}


      <circle cx={origin} cy={origin} r="86" fill="#FFFFFF" />
      <circle cx={origin} cy={origin} r="60" fill={$(theme.COLOR('app-chrome'))} />
    

    <g className={cls.sub('navigator-label')}>
      <path d="M55.4765 154.762L55.3914 152.762L55.3588 152.746L52.6456 158.552L51.4715 158.003L55.018 150.415L56.3878 151.055L56.9052 156.41L56.9903 158.41L57.0229 158.425L59.7361 152.62L60.9102 153.169L57.3637 160.757L55.994 160.117L55.4765 154.762Z" fill="#B2D5D6"/>
      <path d="M65.5989 163.666L65.5804 161.275L62.6015 160.308L61.2048 162.239L59.8923 161.813L65.1956 154.728L66.8163 155.255L66.9457 164.103L65.5989 163.666ZM65.6205 156.178L65.5634 156.16L63.2692 159.301L65.6203 160.064L65.6205 156.178Z" fill="#B2D5D6"/>
      <path d="M70.7966 164.985L69.8211 156.224L71.1954 156.512L71.6518 160.837L71.842 163.856L71.889 163.865L73.2845 161.178L75.4357 157.398L76.7747 157.678L72.3353 165.307L70.7966 164.985Z" fill="#B2D5D6"/>
      <path d="M77.1662 166.139L77.2894 165.042L78.4223 165.169L79.1109 159.04L77.978 158.912L78.1013 157.815L81.7265 158.222L81.6032 159.32L80.4584 159.191L79.7698 165.32L80.9146 165.449L80.7913 166.546L77.1662 166.139Z" fill="#B2D5D6"/>
      <path d="M88.9635 165.612L88.9155 165.611C88.8209 166.018 88.5754 166.354 88.1791 166.62C87.7909 166.877 87.2768 167.001 86.6369 166.991C86.1409 166.983 85.6825 166.883 85.2615 166.692C84.8407 166.493 84.4772 166.212 84.1711 165.846C83.8731 165.474 83.6405 165.018 83.4732 164.479C83.3141 163.932 83.2402 163.307 83.2516 162.603C83.2629 161.907 83.3609 161.293 83.5456 160.76C83.7304 160.219 83.9858 159.767 84.3117 159.404C84.6376 159.041 85.0261 158.771 85.477 158.595C85.928 158.41 86.4295 158.322 86.9815 158.331C87.7334 158.343 88.3665 158.522 88.881 158.866C89.3956 159.202 89.7921 159.669 90.0705 160.266L88.9561 160.896C88.8025 160.501 88.5596 160.181 88.2276 159.936C87.8957 159.682 87.4737 159.551 86.9618 159.543C86.2979 159.532 85.7585 159.735 85.3437 160.153C84.9368 160.57 84.7271 161.167 84.7145 161.943L84.6923 163.311C84.6797 164.087 84.87 164.69 85.263 165.12C85.6641 165.551 86.1966 165.771 86.8605 165.782C87.1245 165.786 87.377 165.759 87.618 165.698C87.8591 165.63 88.0687 165.534 88.2468 165.409C88.4329 165.276 88.5796 165.114 88.6867 164.924C88.8019 164.726 88.8617 164.494 88.866 164.23L88.8765 163.583L87.1487 163.555L87.1676 162.391L90.2032 162.44L90.1307 166.903L88.9429 166.884L88.9635 165.612Z" fill="#B2D5D6"/>
      <path d="M98.6752 166.172L97.6802 163.998L94.5662 164.334L94.0818 166.668L92.7098 166.816L94.6505 158.182L96.3447 157.999L100.083 166.02L98.6752 166.172ZM95.6315 159.331L95.5719 159.338L94.7635 163.142L97.2212 162.877L95.6315 159.331Z" fill="#B2D5D6"/>
      <path d="M103.38 158.034L104.857 165.056L103.53 165.335L102.053 158.313L99.6104 158.827L99.3634 157.653L105.575 156.346L105.822 157.52L103.38 158.034Z" fill="#B2D5D6"/>
      <path d="M112.685 163.075C112.169 163.246 111.669 163.314 111.186 163.28C110.708 163.236 110.26 163.093 109.843 162.852C109.432 162.6 109.058 162.248 108.723 161.794C108.392 161.33 108.117 160.764 107.896 160.096C107.675 159.428 107.56 158.813 107.551 158.251C107.547 157.679 107.637 157.173 107.82 156.734C108.008 156.284 108.282 155.902 108.642 155.59C109.007 155.267 109.448 155.02 109.965 154.849C110.481 154.678 110.978 154.615 111.456 154.659C111.94 154.693 112.387 154.836 112.799 155.087C113.216 155.328 113.589 155.681 113.92 156.145C114.255 156.598 114.533 157.159 114.754 157.828C114.975 158.496 115.088 159.116 115.091 159.688C115.1 160.25 115.01 160.755 114.822 161.205C114.639 161.645 114.365 162.026 114 162.349C113.64 162.662 113.202 162.904 112.685 163.075ZM112.305 161.924C112.608 161.824 112.868 161.679 113.084 161.489C113.307 161.298 113.473 161.074 113.582 160.819C113.697 160.554 113.756 160.26 113.759 159.939C113.762 159.618 113.703 159.275 113.583 158.91L113.168 157.657C113.048 157.292 112.89 156.982 112.696 156.726C112.502 156.47 112.281 156.274 112.034 156.136C111.791 155.989 111.523 155.905 111.23 155.884C110.944 155.861 110.649 155.899 110.345 156C110.034 156.103 109.77 156.249 109.555 156.438C109.339 156.627 109.174 156.854 109.06 157.12C108.951 157.375 108.894 157.663 108.891 157.985C108.888 158.306 108.946 158.649 109.067 159.013L109.481 160.267C109.602 160.631 109.759 160.942 109.954 161.198C110.148 161.453 110.366 161.655 110.609 161.802C110.856 161.94 111.123 162.02 111.409 162.043C111.695 162.067 111.993 162.027 112.305 161.924Z" fill="#B2D5D6"/>
      <path d="M120.186 160.043L118.96 160.623L115.38 153.051L118.645 151.507C119.325 151.185 119.957 151.138 120.543 151.366C121.125 151.586 121.582 152.047 121.914 152.748C122.17 153.291 122.257 153.803 122.175 154.284C122.097 154.755 121.85 155.177 121.433 155.551L124.58 157.965L123.213 158.612L120.247 156.271L118.74 156.984L120.186 160.043ZM120.205 155.03C120.516 154.883 120.721 154.689 120.819 154.448C120.914 154.199 120.889 153.923 120.746 153.619L120.5 153.099C120.356 152.795 120.16 152.604 119.911 152.527C119.659 152.443 119.377 152.475 119.066 152.622L117.113 153.545L118.252 155.953L120.205 155.03Z" fill="#B2D5D6"/>
    </g>

    <g className={cls.sub('net-zero-label')}>
      <path d="M71.16 75.11L69.284 71.19H69.2V84.35H65.196V64.806H69.844L75.192 74.046L77.068 77.966H77.152V64.806H81.156V84.35H76.508L71.16 75.11ZM85.4578 84.35V64.806H98.7578V68.586H89.7138V72.59H97.4698V76.342H89.7138V80.57H98.7578V84.35H85.4578ZM110.614 68.586V84.35H106.358V68.586H101.066V64.806H115.906V68.586H110.614Z" fill="white"/>
      <path d="M69.464 108.35H54.652V104.57L64.284 92.586H55.128V88.806H69.24V92.586L59.58 104.57H69.464V108.35ZM72.7238 108.35V88.806H86.0238V92.586H76.9798V96.59H84.7358V100.342H76.9798V104.57H86.0238V108.35H72.7238ZM93.9876 108.35H89.7316V88.806H98.9996C99.8956 88.806 100.708 88.9553 101.436 89.254C102.164 89.5527 102.78 89.982 103.284 90.542C103.806 91.0833 104.208 91.7367 104.488 92.502C104.768 93.2673 104.908 94.1167 104.908 95.05C104.908 96.3753 104.609 97.5327 104.012 98.522C103.433 99.5113 102.546 100.23 101.352 100.678L105.188 108.35H100.456L97.0396 101.182H93.9876V108.35ZM98.4676 97.598C99.1023 97.598 99.597 97.4393 99.9516 97.122C100.325 96.786 100.512 96.3007 100.512 95.666V94.434C100.512 93.7993 100.325 93.3233 99.9516 93.006C99.597 92.67 99.1023 92.502 98.4676 92.502H93.9876V97.598H98.4676ZM116.165 108.686C114.877 108.686 113.701 108.471 112.637 108.042C111.573 107.613 110.668 106.978 109.921 106.138C109.175 105.279 108.596 104.225 108.185 102.974C107.775 101.705 107.569 100.239 107.569 98.578C107.569 96.9167 107.775 95.4607 108.185 94.21C108.596 92.9407 109.175 91.886 109.921 91.046C110.668 90.1873 111.573 89.5433 112.637 89.114C113.701 88.6847 114.877 88.47 116.165 88.47C117.453 88.47 118.629 88.6847 119.693 89.114C120.757 89.5433 121.663 90.1873 122.409 91.046C123.156 91.886 123.735 92.9407 124.145 94.21C124.556 95.4607 124.761 96.9167 124.761 98.578C124.761 100.239 124.556 101.705 124.145 102.974C123.735 104.225 123.156 105.279 122.409 106.138C121.663 106.978 120.757 107.613 119.693 108.042C118.629 108.471 117.453 108.686 116.165 108.686ZM116.165 104.906C117.453 104.906 118.452 104.486 119.161 103.646C119.889 102.806 120.253 101.602 120.253 100.034V97.122C120.253 95.554 119.889 94.35 119.161 93.51C118.452 92.67 117.453 92.25 116.165 92.25C114.877 92.25 113.869 92.67 113.141 93.51C112.432 94.35 112.077 95.554 112.077 97.122V100.034C112.077 101.602 112.432 102.806 113.141 103.646C113.869 104.486 114.877 104.906 116.165 104.906Z" fill="white"/>
    </g>

    </My.Container>
  )
};

NavigatorMapCore.cls = cls;
NavigatorMapCore.defaultProps = NavigatorMapCoreDefaultProps;