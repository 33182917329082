import React from 'react';
import { Classable, EntityTypeName, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  useFullscreen, usePageEntity, useReady, useContributeForm,
  useOrganisations,
  TrendCard,
  TopicCard,
  FeedEntryCard,
  OrganisationValue, 
  UiConfig,
  UiProvider,
  LayoutShell,
  SiteFooterLayout,
  PersonCard,
  FileAssetCard,
  useEmbedComponents,
  EmbedComponentsProvider,
  Content,
  useColor,
  EconomyExplorerLayout,
  EntityExplorerLayout,
  AppDialog,
} from '@shapeable/ui';

import { MapConfig, MapProvider, useMapUtils, EntityMapPanel } from '@shapeable/maps';
import { GptProvider } from '@shapeable/gpt';

import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { TechnologyIcon } from './elements/technology-icon';
import { InstrumentIcon } from './elements/instrument-icon';
import { LANG_STRINGS } from '../lang';
import { includes } from 'lodash';
import { BLUE, COLOR_DARK_GREY, COLOR_VALUE_DARK_BLUE, COLOR_VALUE_TRANSPORTATION, LayoutStyles, themeOverrides, YELLOW, YELLOW_DARK } from '../theme';
import { 
  TrendsProvider, PagesProvider, HorizonTypesProvider, 
  CitationsProvider, TechnologiesProvider, InstrumentsProvider,
  InstrumentTypesProvider,
  OrganisationsProvider,
  OrganisationTypesProvider, FeedEntriesProvider, PostsProvider,
  GlobalRegionsProvider,
  EconomiesProvider,
} from '../gatsby/providers';
import { CitationCard } from './entities/citation-card';
import { SidebarButtons } from './elements/sidebar-buttons';
import { InstrumentTypeIcon } from './icons/instrument-type-icon';
import { OrganisationTooltip } from './entities/organisation-tooltip';
import { ContributeFormPanel } from './elements/contribute-form-panel';
import { TopicsProvider } from '../gatsby/providers/topics-provider';
import { Organisation } from '@shapeable/adsw-types';

import FILE_ASSET_IMAGE from '../images/file-data-image.png';
import { EntityValueCard } from './entities/entity-value-card';
import { PeopleProvider } from '../gatsby/providers/people-provider';
import { LinkValue } from './entities/link-value';
import { GlobeIconGlyph } from '@shapeable/icons';
import { LinearNavigationProvider } from './providers/linear-navigation-provider';

import * as EntityPageQueries from '../graphql';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { TrendExplorerLayout } from './entities/trend-explorer-layout';
import { TopicExplorerLayout } from './entities/topic-explorer-layout';

export type LayoutPropTypes = Classable & HasChildren & {
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const MapStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});

const ContributeFormStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 800;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});

const DialogStyles = breakpoints({
  base: css`
    position: absolute;
    width: 500px;
    left: 100px;
    top: 100px;
  `,
});


const ContentStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(7)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Dialog: styled(AppDialog)`${DialogStyles}`,
    ContributeForm: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormStyles}`,
    Map: styled(EntityMapPanel)`${MapStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

  Content: styled(Content)`${ContentStyles}`

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {

  const { className, children } = props;
  const fullscreen = useFullscreen();
  const contributeForm = useContributeForm();

  const embedComponents = useEmbedComponents();

  // configure packages

  const mapConfig: MapConfig = {
    initialMapState: {
      isVisible: false,
      isInitialized: false,
      zoom: 3.35,
      entityTypeNames: [ 'Organisation' ],
      latitude: 24.015,
      longitude: 41.644,
    },
    // this map requires special Trend filtering, so we need to turn off default entity type filtering
    applyEntityTypeFilters: () => false,
    items: () => {
      const { entityTypeIsVisible, entityTypeNames } = useMapUtils();
      const organisations = useOrganisations() as Organisation[];
      return React.useMemo(
        () => {
          // selection of "Organisation" means ALL orgs are selected, so don't filter anything. 
          // This works because this toggle is switched off when any innovation system is made active
          if (entityTypeIsVisible('Organisation')) {
            return organisations;
          }

          return organisations.filter(organisation => organisation?.trends.some(trend => entityTypeIsVisible(trend.name as EntityTypeName)));
        }, [ organisations, entityTypeNames ]
      );
    },
  };
  
  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    header: {
      // menuItemIsAvailable: menuItemIsAvailable(auth),
      // variant: 'overlay',
      boundary: fullscreen.isActive ? 'padding' : 'content',
      showThemeMode: true,
    },
    sidebar: {
      isAvailable: () => true,
      widthMenu: 56,
      width: 56,
      showFullscreenButton: true,
      color: COLOR_VALUE_DARK_BLUE,
      buttons: <SidebarButtons />,
    },
    footer: {
      // ref: footerRef,
      backgroundColor: '#EBEBEB',
      verticalPadding: 'slim',
    },
    menuBar: {
      // backgroundColor: COLOR_VALUE_BUILT_ENVIRONMENT,
      delay: 0,
      slideDownDuration: 0,
      showShadow: false,
    },
    explorer: {
      authenticationRequired: true,
      siteHeaderProvider: {
        boundary: 'content',
      }
    },
    theme: themeOverrides,
    entity: {
      images: {
        PdfFileAsset: {
          url: FILE_ASSET_IMAGE,
        },
      },
      labels: {
        Trend: 'Innovation System',
        Topic: 'Focus Area',
      },
      layouts: {
        Economy: EconomyExplorerLayout,
        Organisation: EntityExplorerLayout,
        Person: EntityExplorerLayout,
        Trend: TrendExplorerLayout,
        Topic: TopicExplorerLayout,
      },
      pageQueries: EntityPageQueries,
      dataProviders: [
        TrendsProvider, TopicsProvider, CitationsProvider, TechnologiesProvider,
        InstrumentsProvider, HorizonTypesProvider, PagesProvider, EconomiesProvider,
        InstrumentTypesProvider, OrganisationsProvider, GlobalRegionsProvider,
        OrganisationTypesProvider, FeedEntriesProvider, PostsProvider, PeopleProvider,
      ],
      icons: {
        Technology: TechnologyIcon,
        Instrument: InstrumentIcon,
        InstrumentType: InstrumentTypeIcon,
        Link: GlobeIconGlyph,
      },
      cards: {
        FileAsset: FileAssetCard,
        Citation: CitationCard,
        FeedEntry: FeedEntryCard,
        Trend: TrendCard,
        Topic: TopicCard,
        Instrument: EntityValueCard,
        Technology: EntityValueCard,
        Person: PersonCard,
      },
      tooltips: {
        Organisation: OrganisationTooltip,
      },
      values: {
        Organisation: OrganisationValue,
        Link: LinkValue,
      },
    },
    componentProps: {
      TopicExpertiseValue: {
        showLabel: false,
      },
      PersonValue: {
        showOrganisation: false,
      },
      MenuItemLink: {
        colors: {
          active: {
            text: YELLOW_DARK,
            border: YELLOW_DARK,
          },
          hover: {
            text: YELLOW_DARK,
          }
        }
      },
      NavigationMenuItem: {
        colors: {
          hover: {
            background: BLUE,
            text: 'light',
          }
        }
      },
      MenuItem: {
        colors: {
          hover: {
            background: BLUE,
            text: 'light',
          }
        }
      },
    }
  };
  
  const entity = usePageEntity();
  const isExplorer = includes(['Topic', 'Trend', 'Economy'], entity?.entityTypeName) || entity.entityPath === '/navigator' || entity.slug === 'gpt';

  // const headerWidth = headerDimensions?.width || 0; 

  const headerWidth = 0;

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (headerWidth > 968) {
        document.body.style.overflow = isExplorer ? 'hidden' : 'auto';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isExplorer, headerWidth]);

  return (
    <GptProvider>
    <LinearNavigationProvider>
    <UiProvider value={config}>
    <MapProvider value={mapConfig}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
      Accent: (attr: any) => {
        const color = useColor(attr.color);
        return (
          <My.Content bulletColor={color?.value} bulletSize={'large'} >{attr.children}</My.Content>
        );
      },
      }}>
      <My.Container
        className={className}
        includeTooltip
        hideOverflow={false}
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }
      }>
      <My.Map />
      <My.ContributeForm isVisible={contributeForm.isVisible} />
      <My.Body>
      {children}
      </My.Body>
      {
        !isExplorer && 
        <My.Footer />
      }
      </My.Container>
    </EmbedComponentsProvider>
    </MapProvider>
    </UiProvider>
    </LinearNavigationProvider>
    </GptProvider>
  );
};

Layout.defaultProps = LayoutDefaultProps;

