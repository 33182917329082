import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, FeedEntry, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiLowerCaseIconGlyph, AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { TopicView, TrendView } from '../../types';
import { Technology, Topic } from '@shapeable/adsw-types';
const cls = classNames('topic-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicTabsLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicTabsLayoutDefaultProps: Omit<TopicTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TopicTabsLayout: Shapeable.FC<TopicTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color, technologies: technologiesEntity = [], instruments: instrumentsEntity = [], 
    organisations = [], posts = [], citations = [], feedEntries = [], fileAssets = [] } = entity;

  const libraryItems = [...citations, ...posts, ...feedEntries, ...fileAssets];

  const hasOrganisations = !!organisations.length;
  const hasTechnologies = !!technologiesEntity.length;
  const hasInstruments = !!instrumentsEntity.length;
  const hasLibraryItems = !!libraryItems.length;

  const filteredTechnologyArticles = technologiesEntity.map((tech: Technology) => {
    const filteredFeedEntries = tech?.feedEntries.filter(techFeed => 
      feedEntries.some((feed: FeedEntry) => techFeed.id === feed.id)
    );
    return {
      ...tech,
      feedEntries: filteredFeedEntries
    }
  });

  const tabs: ContentTabSpec<TopicView>[] = compact([
    {
      id: 'navigator-ai',
      label: 'ShapeAI',
      icon: <AiLowerCaseIconGlyph size={32} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    hasTechnologies && {
      id: 'technologies',
      label: 'Technologies',
      count: filteredTechnologyArticles.length,
    },
    hasInstruments && {
      id: 'instruments',
      label: 'Instruments',
      count: instrumentsEntity.length,
    },
    hasOrganisations && {
      id: 'stakeholders',
      label: 'Stakeholders',
      count: organisations.length,
    },
    hasLibraryItems && {
      id: 'library',
      label: 'Resources',
      count: libraryItems.length,
    },
  ]);

  const { activeView, showView, setActiveView } = useView<TopicView>(`topic-${entity.slug}`, 'overview');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value, hover: { text: color?.value } }} />
  )
};

TopicTabsLayout.defaultProps = TopicTabsLayoutDefaultProps;
TopicTabsLayout.cls = cls;