import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { Instrument, Organisation, Technology, Topic } from '@shapeable/adsw-types';
import { ContentEntityIntro, ContentNode, ContentTitle, EntityFilters, EntityGrid, NoResultsLayout, OrganisationLogoCard, OrganisationMarketMapGrid, useEconomies, useLang, useOrganisations, useOrganisationTypes, useView } from '@shapeable/ui';
import { IconContentReveal } from '../elements/icon-content-reveal';
import { TechnologyContentSource } from './technology-content-source';
import { GptLayout } from '@shapeable/gpt';
import { useInstruments } from '../../hooks/use-instruments';
import { useTechnologies } from '../../hooks/use-technologies';
import { useInstrumentTypes } from '../../hooks/use-instrument-types';
import { COLOR_VALUE_LIGHT_BLUE_20 } from '../../theme';
import { TopicView } from '../../types';
import { compact, sortBy } from 'lodash';
import { FundingTrendsIcon, PartnershipsTrendsIcon, PolicyTrendsIcon } from '@shapeable/icons';
import { InstrumentCard } from './instrument-card';
const cls = classNames('topic-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicViewLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicViewLayoutDefaultProps: Omit<TopicViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(2)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const StakeholderSectionStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: #5CACC3;
    font-size: 1em;
    margin-bottom: 0;
    text-transform: uppercase;
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const TechnologiesListStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(12)};
  `,
});

const TechnologyStyles = breakpoints({
  base: css`
    
  `,
});

const InstrumentsListHeaderStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(4)};
  `,
});

const InstrumentLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
  `,
});

const InstrumentFiltersStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
  `,
});

const InstrumentsListStyles = breakpoints({
  base: css`
    
  `,
});

const InstrumentsStyles = breakpoints({
  base: css`
    
  `,
});

const StakeholderGridStyles = breakpoints({
  base: css`
    background-color: ${theme.COLOR('app-chrome')};
  `,
  desktopDevice: css`
    padding: ${theme.UNIT(3)};
    border-radius: ${theme.UNIT(2)};
`
});

const IconContentRevealStyles = breakpoints({
  base: css`
    
  `,
});

const ContentTrendsStyles = breakpoints({
  base: css`
    ${DescriptionStyles};
  `,
});

const LibraryContainerStyles = breakpoints({
  base: css`
    background-color: ${theme.COLOR('app-chrome')};
    padding: ${theme.UNIT(3)};
    padding-bottom: 0;
    border-radius: ${theme.UNIT(2)};
  `
});

const LibraryGridStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;

  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,

    Section: styled.section`${SectionStyles}`,
      Title: styled(ContentTitle)`${TitleStyles}`, 
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,

      IconContentReveal: styled(IconContentReveal)`${IconContentRevealStyles}`,
      ContentTrends: styled(ContentNode)`${ContentTrendsStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`, 

      TechnologiesList: styled.div`${TechnologiesListStyles}`,
        Technology: styled(TechnologyContentSource)`${TechnologyStyles}`,

      InstrumentsListHeader: styled.div`${InstrumentsListHeaderStyles}`,
        InstrumentLabel: styled.span`${InstrumentLabelStyles}`,
        InstrumentFilters: styled(EntityFilters)`${InstrumentFiltersStyles}`,
      InstrumentsList: styled.div`${InstrumentsListStyles}`,
        Instruments: styled(EntityGrid)`${InstrumentsStyles}`,

      StakeholderSection: styled.div`${StakeholderSectionStyles}`,
        StakeholderGrid: styled(EntityGrid)`${StakeholderGridStyles}`,

      LibraryContainer: styled.div`${LibraryContainerStyles}`,
      LibraryGrid: styled(EntityGrid)`${LibraryGridStyles}`,

      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const TopicViewLayout: Shapeable.FC<TopicViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, technologies: technologiesEntity = [], instruments: instrumentsEntity = [], 
    organisations = [], fundingTrends, policyTrends, partnershipTrends, technologiesIntro, instrumentsIntro,
    stakeholdersIntro, libraryIntro, posts = [], citations = [], feedEntries = [], fileAssets = [] } = entity;

    const view = `topic-${entity.slug}`;
    const { showView, resultsFor, filters } = useView<TopicView>(view, 'overview');
    const { technologies = [], instruments = [], type = [], types = [], economies = [] } = filters;
  
    const libraryItems = [...citations, ...posts, ...feedEntries, ...fileAssets];
  
    const hasTechnologies = !!technologiesEntity.length;
    const hasInstruments = !!instrumentsEntity.length;
    const hasStakeholders = !!organisations.length;
    const hasLibraryItems = !!libraryItems.length;
  
    const hasFundingTrends = !!fundingTrends?.text;
    const hasPolicyTrends = !!policyTrends?.text;
    const hasPartnershipTrends = !!partnershipTrends?.text;
    const hasTechnologiesIntro = !!technologiesIntro?.text;
    const hasInstrumentsIntro = !!instrumentsIntro?.text;
    const hasStakeholdersIntro = !!stakeholdersIntro?.text;
    const hasLibraryIntro = !!libraryIntro?.text;
  
    const allInstruments = useInstruments();
    const allTechnologies = useTechnologies();
    const allOrganisationTypes = useOrganisationTypes();
    const allEconomies = useEconomies();
    const allInstrumentTypes = useInstrumentTypes();
    const allOrganisations = useOrganisations();
  
    const t = useLang();
  
    const hasOrganisations = !!organisations.length;
    const hasEconomies = organisations.some(organisation => organisation?.countries?.length);
    const hasTechInstruments = technologiesEntity.flatMap(tech => tech.instruments);
    const hasInstrumentTypes = allInstruments.flatMap(instrument => instrument?.types);
  
    // augmenting Technologies as request was made to have the feedEntries associated with the 
    // technology match the Topic feedEntries
    const filteredTechnologyArticles = technologiesEntity.map(tech => {
      const filteredFeedEntries = tech?.feedEntries.filter(techFeed => 
        feedEntries.some(feed => techFeed.id === feed.id)
      );
      return {
        ...tech,
        feedEntries: filteredFeedEntries
      }
    });

    const filteredTechnologies = filteredTechnologyArticles.filter(technology => (
      linkedMatchesFilter(compact([technology]), technologies) &&
      linkedMatchesFilter(technology.instruments, instruments)
    ));
  
    const filteredStakeholders = organisations.filter(organisation => (
      linkedMatchesFilter(compact([organisation?.type]), type) &&
      linkedMatchesFilter(organisation?.countries, economies)
    ));
  
    const hasFilteredStakeholders = !!filteredStakeholders.length;
  
    const instrumentResults = instrumentsEntity.filter(instrument => (
      linkedMatchesFilter(instrument?.types, types)
    ));
  
    const library = sortBy([
      ...resultsFor({type: 'Post', data: posts, by: 'name'}),
      ...resultsFor({type: 'FileAsset', data: fileAssets, by: 'name'}),
      ...resultsFor({type: 'FeedEntry', data: feedEntries, by: 'name'}),
      ...resultsFor({type: 'Citation', data: citations, by: 'name'}),
    ], 'name');
  
  return (
    <My.Container className={cls.name(className)}>
    <My.Body>
    {
      showView('navigator-ai') && 
      <My.GptLayout entity={entity} />
    }
    {
      showView('overview') && 
        <>
          <My.Section>
            <My.Intro entity={entity} />
            <My.Description entity={description} />
          </My.Section>
        </>
    }
    {
      showView('technologies') &&
      <>
        <My.Section>
          <My.Title>{t("Current Practices, Technologies, and Innovations")}</My.Title>
          {
            hasTechnologiesIntro && <My.Description entity={technologiesIntro} />
          }
        </My.Section>

        <My.Section>
          <My.Title>{t("Explore Technologies")}</My.Title>
          <My.Filters
          results={filteredTechnologyArticles}
          view={view}
          selects={compact([
            !!allTechnologies.length && {
              name: 'technologies',
              items: allTechnologies, 
              pluralLabel: 'Technologies',
              getResultFieldSlugs: (result: Technology) => [result?.slug],
            },
            !!hasTechInstruments.length && {
              name: 'instruments',
              items: allInstruments, 
              pluralLabel: 'Instruments',
              getResultFieldSlugs: (result: Technology) => result?.instruments.map(instrument => instrument?.slug),
            }
          ])}
          />
        <My.TechnologiesList>
          {
            hasTechnologies &&
            !!filteredTechnologies.length ? 
            filteredTechnologies.map(technology => <My.Technology key={technology.id} entity={technology} />) 
            : <NoResultsLayout >{t('No results found')}</NoResultsLayout> 
          }
        </My.TechnologiesList>
        </My.Section>
      </>
    }
    {
      showView('instruments') &&
      <>
        <My.Section>
          {
            hasInstrumentsIntro && <My.Description entity={instrumentsIntro} />
          }
        </My.Section>
          {
            hasPolicyTrends &&  <My.IconContentReveal description={policyTrends} icon={<PolicyTrendsIcon colors={{ fill: 'strong' }} />}>{t("Policy")}</My.IconContentReveal>
          }
          {
            hasFundingTrends && <My.IconContentReveal description={fundingTrends} icon={<FundingTrendsIcon colors={{ fill: 'strong' }} />}>{t("Funding")}</My.IconContentReveal>
          }
          {
            hasPartnershipTrends && <My.IconContentReveal description={partnershipTrends} icon={<PartnershipsTrendsIcon colors={{ fill: 'strong' }} />}>{t("Partnership")}</My.IconContentReveal>
          }

        <My.Section>
          <My.InstrumentsListHeader>
            <My.InstrumentLabel>{t('Instruments:')}</My.InstrumentLabel>
            <My.InstrumentFilters
            results={instrumentsEntity}
            view={view}
            selects={compact([
              hasInstrumentTypes && {
                name: 'types',
                items: allInstrumentTypes, 
                pluralLabel: 'Instrument Types',
                getResultFieldSlugs: (result: Instrument) => result?.types?.map(type => type?.slug),
              }
            ])}
            />
          </My.InstrumentsListHeader>
        <My.InstrumentsList>
          {
            hasInstruments &&
            <My.Instruments 
              items={instrumentResults} 
              card={InstrumentCard} 
              landscapeAutoColumns={1}
              tabletAutoColumns={2}
              desktopAutoColumns={2} 
              desktopLargeAutoColumns={4}
              desktopLargeMinColumns={4}
              desktopFullHdAutoColumns={4}
              spacing={4} />
          }
        </My.InstrumentsList>
        </My.Section>
      </>
    }
    {
        showView('stakeholders') && 
        <>
        <My.StakeholderSection>
          {
            hasStakeholdersIntro && <My.Description entity={stakeholdersIntro} />
          }
          <My.Title>{t("Market Map")}</My.Title>
          <My.Filters 
            results={organisations}
            view={view}
            selects={compact([
              {
                name: 'type',
                items: allOrganisationTypes, 
                pluralLabel: 'Stakeholder Types',
                getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
              },
              hasEconomies && {
                name: 'economies',
                items: allEconomies, 
                pluralLabel: 'Countries',
                getResultFieldSlugs: (result: Organisation) => result.countries?.map(country => country?.slug),
              }
            ])}
          />
        </My.StakeholderSection>

        <My.Section>
        {
          hasOrganisations && 
          filteredStakeholders.length ? (
            <My.StakeholderGrid 
              items={filteredStakeholders} 
              card={OrganisationLogoCard} 
              minColumns={2}
              spacing={3}
              landscapeAutoColumns={4}
              tabletAutoColumns={4}
              desktopAutoColumns={5}
              desktopLargeMinColumns={6}
              desktopFullHdAutoColumns={6}
              /> 
           ) : (
          <NoResultsLayout >{t('No results found')}</NoResultsLayout>
           )
        }
        </My.Section>
        </>
      }
      {
        showView('library') && 
        <>
          {
          hasLibraryIntro && 
          <My.Section>
          {
            <My.Description entity={libraryIntro} />
          }
          </My.Section>
          }

          <My.Section>
            <My.LibraryContainer>
              <My.Filters 
                view={view}
                typeToggles={compact([
                  !!posts.length && {
                    name: 'Post',
                    label: 'Posts',
                  },
                  !!fileAssets.length && {
                    name: 'FileAsset',
                    label: 'Reports',
                  },
                  !!feedEntries.length && {
                    name: 'FeedEntry',
                    label: 'Articles',
                  },
                  !!citations.length && {
                    name: 'Citation',
                    label: 'External Articles',
                  }, 
                ])}
              />

              <My.LibraryGrid items={library} desktopAutoColumns={3} spacing={2} />
            </My.LibraryContainer>
          </My.Section>
        </>
      }
      </My.Body>
    </My.Container>
  )
};

TopicViewLayout.defaultProps = TopicViewLayoutDefaultProps;
TopicViewLayout.cls = cls;