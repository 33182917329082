import { breakpoints, theme } from "@shapeable/theme";
import { css } from "styled-components";

export const YELLOW = '#FFCA04';
export const YELLOW_DARK = '#FCB10F';
export const YELLOW_ORANGE = '#F79616';

export const BLUE = '#5CACC3';

export const COLOR_VALUE_ENERGY = '#FDD318';
export const COLOR_VALUE_TRANSPORTATION = '#F29220';
export const COLOR_VALUE_MANUFACTURING = '#59595B';
export const COLOR_VALUE_BUILT_ENVIRONMENT = '#278689';
export const COLOR_VALUE_WATER = '#32B1C3';
export const COLOR_VALUE_AGRICULTURE = '#72BE44';

export const COLOR_VALUE_DARK_BLUE = '#638985';

export const COLOR_VALUE_LIGHT_BLUE_20 = '#EFF7F9';

export const COLOR_DARK_GREY = '#404042';

export const COLOR_1_TO_5_1 = '#FF0000';
export const COLOR_1_TO_5_2 = '#F47B20';
export const COLOR_1_TO_5_3 = '#FCAF18';
export const COLOR_1_TO_5_4 = '#BDC02B';
export const COLOR_1_TO_5_5 = '#56C02B';

export const COUNTRY_BAR_CHART_GREY = '#ADAD91';

  

export const themeOverrides = {
  font: {
    family: {
      heading: `'IBM Plex Sans', sans-serif`,
      serif: `'Lustria', serif`,
    },
  },
  light: {
    color: {
      
      appChrome: '#EDEDE7',
      strongChrome: '#ADAD91',
      textChrome: '#8A8A74',
      subtleChrome: '#F8F8F5',

      // darkBackground: '#202124',
      // lightBackground: '#F8F8F8',
  
      midBrand: YELLOW_DARK,
      primary: BLUE,
  
      heading: BLUE,
  
      linkHover: YELLOW,
      // link: YELLOW_DARK,
      // linkInvertedHover: YELLOW,
      // linkActive: YELLOW,
  
      // menuItemLinkHover: YELLOW_DARK, 
      // menuItemLinkActive: YELLOW_DARK, 
      // invertedMenuItemLinkHover: YELLOW_DARK, 
  
    },
  },
  dark: {
    color: {
      strongChrome: '#ADAD91',
      midBrand: YELLOW_DARK,
      primary: BLUE,
      heading: BLUE,
      linkHover: YELLOW,
    },
  }
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .shp--entity-map {
      margin-right: 56px;
    }

    .shp--organisation-powered-by {
      img {
        mix-blend-mode: multiply;
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }


    .shp--slice-intro {
      font-size: 1.75em;
      text-align: left;
      line-height: 1.25em;
    }

    .shp--slice-section-color-block {
      max-height: 600px;
    }

    .shp--slice-key-message .shp--content {
      font-style: normal;
      font-weight: 400;
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }
  `,
  desktop: css`
    
  `,
});