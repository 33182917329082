import { compact } from "lodash";
import { useEconomies, useGlobalRegions, useOrganisations, useOrganisationTypes, useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { sortBy } from "lodash";
import { Economy, Organisation } from "@shapeable/adsw-types";
import { GlobalRegion, OrganisationType } from "@shapeable/types";

export const useEconomyFilters = () => {
  const globalRegions = useGlobalRegions();
  const economies = useEconomies();

  // reduce the ste of global regions to just those used in records
  const filterGlobalRegions = (economies: Economy[], globalRegions: GlobalRegion[]) => {
    return globalRegions.filter((globalRegion: GlobalRegion) => 
      economies.some(economy =>
        economy?.globalRegions?.find(region => region.slug === globalRegion.slug)
      ));
  };

  const finalGlobalRegions = filterGlobalRegions(economies, globalRegions);

  return {
    selects: compact([
      {
        name: 'globalRegions',
        items: finalGlobalRegions,
        pluralLabel: 'Global Regions',
        getResultFieldSlugs: (result: Economy) => result.globalRegions?.map(item => item.slug),
      },
    ]),
  };
};