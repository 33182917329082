import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { platformProviderValue } from "@shapeable/ui";
import { InstrumentsProvider as Provider } from "../../components/context/instruments-context";

const QUERY = graphql`
  query InstrumentsQuery {
    platform {
      allInstruments {
        id name slug path __typename _schema { label pluralLabel }
        openGraph { description { id text } image { id url } }
        types { id name slug path __typename _schema { label pluralLabel } }
        topics { id name slug path __typename _schema { label pluralLabel } }
      }
    }
  }
`

export const InstrumentsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allInstruments' ) }>{children}</Provider>;
}