import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, GlobalRegionsProvider as Provider } from '@shapeable/ui';

const GLOBAL_REGIONS_QUERY = graphql`
  query GlobalRegionsQuery {
    platform {
      allGlobalRegions {
        id name slug path __typename _schema { label pluralLabel }
        abbreviation 
        openGraph {
          description { plain }
          image { url thumbnails { card { url url2x } bubble { url url2x } } }
        }
      }
    }
  }
`;

export const GlobalRegionsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(GLOBAL_REGIONS_QUERY), 'allGlobalRegions' ) }>{children}</Provider>;
}