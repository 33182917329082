import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, EconomiesProvider as Provider } from '@shapeable/ui';

const ECONOMIES_QUERY = graphql`
  query EconomiesQuery {
    platform {
      allEconomies {
        __typename _schema { label pluralLabel }
        id path slug name 
        globalRegions { 
          id slug name abbreviation
          openGraph { image { url thumbnails { card { url url2x } bubble { url url2x } } } }
        }
        openGraph { image { url thumbnails { card { url url2x } bubble { url url2x } } } }
      }
    }
  }
`;

export const EconomiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(ECONOMIES_QUERY), 'allEconomies' ) }>{children}</Provider>;
}