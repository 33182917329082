import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentEntity, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Horizon, HorizonType } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, Icon, useEntity, useLang } from '@shapeable/ui';
import { LoginIcon } from '@shapeable/icons';
import { classNames } from '@shapeable/utils';

const cls = classNames('horizon-card');

// -------- Types -------->

export type HorizonCardProps = Classable & HasChildren & { 
  entity?: HorizonType;
  hasTick?: boolean;
  description?: TContentNode;
  horizonNumber?: number;
};

export const HorizonCardDefaultProps: Omit<HorizonCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type BannerProps = {
  border: number;
  hasTick: boolean
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    font-size: 1em;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(1)};
    flex: 1 0 0;
    margin-top: 12px;

  `,
});

const NameStyles = breakpoints({
  base: css`
    display: flex;
    padding: ${theme.UNIT(1)} 0px;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(1)};
    flex: 1 0 0;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: ${theme.COLOR('strong')};
    text-align: center;
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: ${14/16}em;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.15px;
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('strong')};
    font-family: IBM Plex Sans;
    font-size: ${15/16}em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
});


const BannerStyles = breakpoints({
  base: css`
    display: flex;
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)};
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-top: 1px solid ${theme.COLOR('line-mid')};
    border-right: 1px solid ${theme.COLOR('line-mid')};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
    background: var(--Grad, linear-gradient(180deg, #B2D5D6 0%, rgba(178, 213, 214, 0.08) 91.87%, rgba(178, 213, 214, 0.00) 100%));

    ${({ border }: BannerProps) => (border === 25 && css`
      border-right: 1px solid transparent;
  `)}
    ${({ hasTick }: BannerProps) => (!hasTick && css`
      background: ${theme.COLOR('canvas')};
    `)}

  `,
});

const IconStyles = breakpoints({
  base: css`
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: ${theme.COLOR('canvas')};
  `,
});

const ArrowIconStyles = breakpoints({
  base: css`
    width: 36px;
    height: 36px;
  `,
});



const BodyStyles = breakpoints({
  base: css`
    display: flex;
    padding: 12px 16px 12px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Name: styled.div`${NameStyles}`,
      Title: styled.span`${TitleStyles}`,
      Subtitle: styled.span`${SubtitleStyles}`,
    Banner: styled.div.attrs(cls.attr('banner'))<BannerProps>`${BannerStyles}`,
      Icon: styled.div`${IconStyles}`,
      ArrowIcon: styled(LoginIcon)`${ArrowIconStyles}`,
    Body: styled.div`${BodyStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
};

export const HorizonCard: Shapeable.FC<HorizonCardProps> = (props) => {
  const { className, children, entity, hasTick, description, horizonNumber } = props;
  const { years, subtitle, name } = entity;

  const t = useLang();

  return (
   <My.Container className={cls.name(className)}>
    <My.Banner hasTick={hasTick} border={years}>
      <My.Name>
      <My.Title>{t(`HORIZON ${horizonNumber + 1}`)}</My.Title>
      <My.Subtitle>{name}</My.Subtitle>
      </My.Name>
  
      <My.Icon>
        {
          hasTick && <My.ArrowIcon colors={{ fill: '#5CACC3' }} />
        }
      </My.Icon>
    </My.Banner>
    <My.Body>
       {
        hasTick && <My.Description entity={description as TContentNode} />
       }
    </My.Body>
   </My.Container>
  )
};

HorizonCard.defaultProps = HorizonCardDefaultProps;
HorizonCard.cls = cls;