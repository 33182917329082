import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageKnowledgeHubLayout, SliceLayoutBoundary, useEntity, useFeedEntries, usePages } from '@shapeable/ui';
import { filterPagesByType } from '../../utils/filter-pages-by-type';
import { EntityTitleHeader, SiteHeaderProvider } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-knowledge-hub');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  .shp--entity-title-header__title {
    max-width: 500px;
    font-weight: 300;
    font-size: 3rem;
    strong {
      color: ${theme.COLOR('light')};  
    }
    
  }
  
  `,
  tablet: css`
  .shp--entity-title-header__title {
    font-size: 3.5rem;
  }
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutKnowledgeHub: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const articles = useFeedEntries();

  const { banner } = entity;

  const articleTypeNews = [...filterPagesByType(articles, 'News')];

  const types = {
    FeedEntry: {
      label: 'Resources',
      sortBy: 'published',
      reverse: true,
      spacing: 2,
      items: articleTypeNews,
    },
  };

  return (
    <SiteHeaderProvider value={{ variant: 'overlay' }}>
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader colors={{ background: '#638985B3', text: 'canvas' }} backgroundImage={banner} entity={entity} overlayColor='#63898580' />
      {slices}
      <SliceLayoutBoundary>
      <PageKnowledgeHubLayout
        entity={entity}
        filterSize='slim'
        filterSpacing={3}
        showAllTab={true}
        allTabLabel='All Knowledge'
        entityTypes={types}
      />
      </SliceLayoutBoundary>
      {children}
    </My.Container>
    </SiteHeaderProvider>
  )
};