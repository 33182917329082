import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode, Organisation, Page, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentEntityIntro, ContentNode, EntityCard, EntityValuePill, IconTextValue, OrganisationPoweredBy, SliceJumpLinks, useEntity, useFeedEntries, useLang, useLink, usePages } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { organisationLogo } from '../../data';
import { description } from '../../data';
import { classNames } from '@shapeable/utils';
import { AiLowerCaseIconGlyph, IconSheet } from '@shapeable/icons';
const cls = classNames('explorer-home-view-layout');

// -------- Types -------->

export type ExplorerHomeViewLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerHomeViewLayoutDefaultProps: Omit<ExplorerHomeViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    color: ${theme.COLOR('text')};
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(10)};
    padding-right: ${theme.UNIT(5)};
    padding-top: ${theme.UNIT(5)};
  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 90%;
    height: auto;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.3em;
  `,
});

const StrategicPrioritiesLabelStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: #5CACC3;
    font-weight: 500;
  `,
});

const ItemsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    
  
    a {
      color: ${theme.COLOR('dark')};
      text-decoration: none;

    }

    @media (max-width: 1380px) {
      flex-wrap: wrap;
    }
   
  `,
});

const StrategicPrioritieButtonStyles = breakpoints({
  base: css`
    background-color: #F9F9F9;

    &:hover {
      border-color: ${theme.COLOR('primary')};
      color: ${theme.COLOR('primary')};
    } 

    .shp--image-bubble {
      height: 45px;
      width: 45px;
    }

    .shp--entity-value__name {
      font-size: 12px;
    }
  `,
  desktop: css`

    .shp--image-bubble {
      height: 80px;
      width: 80px;
    }

    .shp--entity-value__name {
      font-size: 14px;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
    font-size: 1em;
  `,
});

const FeaturedNewsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const FeaturedNewsCardStyles = breakpoints({
  base: css`
    
  `,
});

const HostedByLogoStyles = breakpoints({
  base: css`
    display: flex;
    align-self: stretch;
    align-items: flex-end;
    justify-content: flex-end;
  `,
});

const LogoStyles = breakpoints({
  base: css`
    
  `,
});

const JumpLinksStyles = breakpoints({
  base: css`
    
  `,
});

const NavigatorGptPillStyles = breakpoints({
  base: css`
    
    border: 1px solid ${theme.COLOR('line-mid')};
    border-radius: 100px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(6)} ${theme.UNIT(2)} ${theme.UNIT(2)};
    background-color: ${theme.COLOR('light')};
    &:hover {
      border-color: ${theme.COLOR('primary')};
      ${theme.FILL('primary')};
      color: ${theme.COLOR('primary')};
    }

    .shp--icon {
      height: 60px;
      width: 60px;
    }
  `,
  desktop: css`
    .shp--icon {
      height: 80px;
      width: 80px;
    }
  `,
});

const NavigatorToutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.UNIT(4)};
  `,
});

const NavigatorDescriptionStyles = breakpoints({
  base: css`
    color: inherit;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Image: styled.img`${ImageStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Body: styled.div`${BodyStyles}`,
      StrategicPrioritiesLabel: styled(CellLabel)`${StrategicPrioritiesLabelStyles}`,
      Items: styled.div`${ItemsStyles}`,
        StrategicPriorityButton: styled(EntityValuePill)`${StrategicPrioritieButtonStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,

    Section: styled.section`${SectionStyles}`,
      ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
      FeaturedNews: styled.div`${FeaturedNewsStyles}`,
      FeaturedNewsCard: styled(EntityCard)`${FeaturedNewsCardStyles}`,
  
    HostedByLogo: styled.div`${HostedByLogoStyles}`,
      Logo: styled(OrganisationPoweredBy)`${LogoStyles}`,

      JumpLinks: styled(SliceJumpLinks)`${JumpLinksStyles}`,

    NavigatorTout: styled.div.attrs(cls.attr('NavigatorTout'))`${NavigatorToutStyles}`,
      NavigatorGptPill: styled(IconTextValue)`${NavigatorGptPillStyles}`,
      NavigatorDescription: styled(ContentNode)`${NavigatorDescriptionStyles}`,

};

export const ExplorerHomeViewLayout: Shapeable.FC<ExplorerHomeViewLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { Link } = useLink();
  const pages = usePages();
  const t = useLang();

  const feedEntries = useFeedEntries();
  const allItems = sortBy(feedEntries, 'published');

  const strategicPrioritiesPage = pages.find(page => page.name === 'Strategic Priorities');

  const filteredSlices = strategicPrioritiesPage
  ? strategicPrioritiesPage.slices
      .filter(slice => slice?.layout?.component === 'SliceLayoutSection')
      .map(slice => ({
        ...slice,
        image: {
          url: slice?.images?.[0]?.image?.url,
        }
        
      }))
  : [];

  return (
   <My.Container className={cls.name(className)}>
    <My.Intro entity={entity}/>

    <My.Body>
      <My.StrategicPrioritiesLabel>{t('Generate Personalised Results With:')}</My.StrategicPrioritiesLabel>


      <My.NavigatorTout>
        <My.NavigatorGptPill href={'#view=navigator-ai'} iconSize={80} colors={{ text: 'dark' }} iconComponent={AiLowerCaseIconGlyph}>{t('NavigatorAI')}</My.NavigatorGptPill>
        <My.NavigatorDescription entity={{
          text: t(`NavigatorAI is specifically trained on ADSW's Strategic Priorities and its knowledge archive. It can explain valuable relationships and connections between important topics and surface the most relevant related documents and articles you’d need.`)
        }} />
      </My.NavigatorTout>

      <My.Section>
          <My.ContentLabel>{t('Latest News')}</My.ContentLabel>
          <My.FeaturedNews>
          {
            allItems.slice(3, 7).map(item => <My.FeaturedNewsCard key={item.id} direction='row' entity={item} />)
          }
          </My.FeaturedNews>
      </My.Section>

    </My.Body>

    <My.HostedByLogo>
      <My.Logo label='Hosted By:' entity={organisationLogo} />
    </My.HostedByLogo>

   </My.Container>
  )
};

ExplorerHomeViewLayout.defaultProps = ExplorerHomeViewLayoutDefaultProps;
ExplorerHomeViewLayout.cls = cls;