import { compact } from "lodash";
import { useEconomies, useOrganisations, useOrganisationTypes, useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { sortBy } from "lodash";
import { Organisation } from "@shapeable/adsw-types";
import { OrganisationType } from "@shapeable/types";

export const useCommunityFilters = () => {
  const economies = useEconomies();
  const organisationTypes = useOrganisationTypes();
  const organisations = useOrganisations();

  const filterOrgTypes = (entity: Organisation[], types: OrganisationType[]) => {
    return types.filter(type => 
      entity.some(org =>
        org?.type?.slug === type.slug
      ));
  };
  const filterOrgCountries = (entity: Organisation[], countries: OrganisationType[]) => {
    return countries.filter(type => 
      entity.some(org =>
        org?.country?.slug === type.slug
    ));
  }

  const finalOrgTypes = filterOrgTypes(organisations, organisationTypes);
  const finalOrgCountries = filterOrgCountries(organisations, economies);

  return {
    selects: compact([
      {
        name: 'types',
        items: finalOrgTypes,
        pluralLabel: 'Stakeholder Types',
        getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
      },
      !!finalOrgCountries.length &&
        {
          name: 'countries',
          items: finalOrgCountries,
          pluralLabel: 'Countries',
        },
    ]),
    typeToggles: [
      { name: 'Organisation' },
      { name: 'Person' },
    ]
  };
};