module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XC7EVW7VCZ"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8495214b13bc24e655605972396f1d02"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"includeProfile":true,"fonts":["IBM+Plex+Sans:300,300i,400,400i,500,500i,600,600i,700","IBM+Plex+Serif:300,300i,400,400i,500,600","Poppins:300,300i,400,400i,500,500i,600,600i,700","IBM+Plex+Sans+Condensed:300,400,500,600","Lustria:400"],"entities":{"FeedEntry":{},"Citation":{},"Post":{},"FileAsset":{},"OrganisationType":{},"Economy":{},"People":{},"IndicatorGroup":{},"Objective":{},"ProfileType":{},"ExpertiseLevel":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
