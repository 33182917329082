import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { platformProviderValue } from "@shapeable/ui";
import { InstrumentTypesProvider as Provider } from "../../components/context/instrument-types-context";

const QUERY = graphql`
  query InstrumentTypesQuery {
    platform {
      allInstrumentTypes {
        id name slug path __typename _schema { label pluralLabel }
        openGraph { image { id url } }
      }
    }
  }
`

export const InstrumentTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allInstrumentTypes' ) }>{children}</Provider>;
}