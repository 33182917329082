import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Topic } from '@shapeable/types';
import { Technology } from '@shapeable/adsw-types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityValue, Icon, useEntity, useLang, useLink, useTopics } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('technology-content-source');
import { template, map } from 'lodash'
import { InfoIcon } from '@shapeable/icons';
// -------- Types -------->

export type TechnologyContentSourceProps = Classable & HasChildren & { 
  entity?: Technology;
};

export const TechnologyContentSourceDefaultProps: Omit<TechnologyContentSourceProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(6)};

    a {
      color: ${theme.COLOR('strong')};
      text-decoration: none;
    }
    
  `,
});

const IconAndLabelStyles = breakpoints({
  base: css`
    
  `,
});

const SourceLinkInfoStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(2)};
    align-items: flex-start;

    &:hover {
      color: ${theme.COLOR('link-hover')};
    }
  `,
});

const IconStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  
  `,
});

const SourceNameLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
    padding-right: ${theme.UNIT(1)};
  `,
});

const RedirectTextStyles = breakpoints({
  base: css`
    font-weight: 300;
  `,
});

const TextWrapperStyles = breakpoints({
  base: css`
    display: inline-block;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    IconAndLabel: styled(EntityValue)`${IconAndLabelStyles}`,

    SourceLinkInfo: styled.div`${SourceLinkInfoStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
      TextWrapper: styled.div`${TextWrapperStyles}`,
      SourceNameLabel: styled.span`${SourceNameLabelStyles}`,
      RedirectText: styled.span`${RedirectTextStyles}`,

};

export const TechnologyContentSource: Shapeable.FC<TechnologyContentSourceProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { feedEntries } = entity;

const t = useLang();

  const hasFeedEntries = !!feedEntries.length;

  const { Link } = useLink();
  const topics = useTopics() as Topic[];
  

  return (
   <My.Container className={cls.name(className)}>
    <My.IconAndLabel variant='summary' entity={entity} />
    {
      hasFeedEntries &&
        feedEntries.map(feedEntry => {
          const { name, url, websiteName } = feedEntry;
          const sourceText = template(t('Read more on <%= name %>'))({ name });
          const reportName = template(t('You will be redirected to the report <%= name %>'))({ name });
          
          return (
            <Link href={url} key={feedEntry.id}>
              <My.SourceLinkInfo>
                <My.Icon size={20}><InfoIcon /></My.Icon>
                <My.TextWrapper>
                  <My.SourceNameLabel>{sourceText}</My.SourceNameLabel>
                  {/* <My.RedirectText>{reportName}</My.RedirectText> */}
                </My.TextWrapper>
              </My.SourceLinkInfo>
            </Link>
          )
        })
    }
   {children}
   </My.Container>
  )
};

TechnologyContentSource.defaultProps = TechnologyContentSourceDefaultProps;
TechnologyContentSource.cls = cls;